/* Add this to your global CSS file or within a <style> tag in your component */
.card {
    perspective: 1000px;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .card:hover .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .card-front {
    z-index: 2;
    transform: rotateY(0deg);
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  
  .textarea-container {
    height: 100%; /* or a specific height like 300px */
  }
  
  textarea#editor {
    height: 100%; /* Make sure the textarea takes up the full height */
  }

  
  .intro {
    font-weight: bold;
    color: #4CB3A5;
    border-right: 2px solid #4CB3A5;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: blink-caret 0.75s step-end infinite;
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: #4CB3A5; }
  }
  